<template>
  <div id="app">
    <full-page :options="options" id="fullpage" ref="fullpage">
      <div class="section" id="section1" style="color:#fff;">
        <div class="myname"><a href="https://www.suphanutjui.com" style="text-decoration:none;">Suphanut<br>Aneknumwong</a></div>
        <div class="flex-grid">
            <div class="col" style="align-self: center;">
        <h3>เสียงของ</h3>
        <h2 style="font-weight:bold;">Gen Y</h2>
        <p>ในม็อบ 2563</p>
        <h4>ทำไมพวกเขาจึงออกมา<br><strong>ชุมนุมมากที่สุด?</strong></h4>
            </div></div>
      </div>
      <div class="section">
        <div style="height: 100%;">
          <div class="flex-grid">
            <div class="col" style="align-self: center;">
              <div style="padding-top: 10px;">
                <h3>แทนที่จะเป็นนักศึกษา</h3>
                <h3>แต่เกินครึ่งของคนในม็อบ คือ</h3>
                <h2 style="font-weight:bold;">Gen Y<sup>1</sup></h2>
              </div>
            </div>
            <div class="col" style="align-self: center;">
              <img src="@/assets/mobber-stats.png" class="img-diagram">
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="flex-grid question">
          <div class="col" style="align-self: center;color:#fff;">
            <h3 style="font-weight: bold">จึงเกิดคำถามว่า..</h3>
            <h4>พวกเขาเป็นใคร? </h4>
            <h4>ชีวิตพวกเขาผ่านอะไรมาบ้าง?</h4>
            <h4>ทำไม Gen Y ถึงออกมามากที่สุดแทนที่จะเป็นนักศึกษา? </h4>
          </div>
        </div>
      </div>
      <div class="section">
        <div style="height: 100%;">
          <div class="flex-grid question">
            <div class="col" style="align-self: center;">
              <div class="flex-grid question" style="height: auto;">
                <div class="col" style="align-self: center;">
                  <h3>เราจึงไปคุยกับ <br> <strong>Gen Y 5 คน</strong>ในม็อบ</h3>
                  <p>ณ วันที่ 10 ธันวาคม 2563</p>
                </div>
              </div>
              <div class="flex-grid question" style="height: auto;">
                <div class="col" style="align-self: center;">
                  <div class="pre-quote-1">
                    <div class="quote" style="max-width:350px;">
                      <p class="quote-marks">เอย อายุ 27 ปีค่ะ</p>
                      <!--<p class="quote-footer"><strong>Zeb Ikalana</strong>, Okatare Farm Owner</p>-->
                      <img src="@/assets/aey.png" class="quote-avatar">
                    </div>
                  </div>
                </div>
                <div class="col" style="align-self: center;">
                  <div class="pre-quote-2">
                    <div class="quote" style="max-width:350px;">
                      <p class="quote-marks">บัว อายุ 25 ปีค่ะ</p>
                      <!--<p class="quote-footer"><strong>Zeb Ikalana</strong>, Okatare Farm Owner</p>-->
                      <img src="@/assets/bua.png" class="quote-avatar">
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-grid question" style="height: auto;">
                <div class="col" style="align-self: center;">
                  <div class="pre-quote-1">
                    <div class="quote" style="max-width:350px;">
                      <p class="quote-marks">ท็อป อายุ 25 ปีครับ</p>
                      <!--<p class="quote-footer"><strong>Zeb Ikalana</strong>, Okatare Farm Owner</p>-->
                      <img src="@/assets/top.png" class="quote-avatar">
                    </div>
                  </div>
                </div>
                <div class="col" style="align-self: center;">
                  <div class="pre-quote-2">
                    <div class="quote" style="max-width:350px;">
                      <p class="quote-marks">เอิร์ธ อายุ 31 ปีครับ</p>
                      <!--<p class="quote-footer"><strong>Zeb Ikalana</strong>, Okatare Farm Owner</p>-->
                      <img src="@/assets/earth.png" class="quote-avatar">
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-grid question" style="height: auto;">
                <div class="col" style="align-self: center;">
                  <div class="pre-quote">
                    <div class="quote" style="max-width:350px;">
                      <p class="quote-marks">วี อายุ 23 ปีค่ะ</p>
                      <!--<p class="quote-footer"><strong>Zeb Ikalana</strong>, Okatare Farm Owner</p>-->
                      <img src="@/assets/vee.png" class="quote-avatar">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div style="height: 100%;">
          <div class="flex-grid">
            <div class="col" style="align-self: center;">
              <h3 style="font-weight:bold;">ไปม็อบบ่อยไหม?</h3>
              <h4>ทั้ง 5 คนที่เราสัมภาษณ์ตอบตรงกันว่า</h4>
              <div style="display:flex;justify-content: center;">
                <div class="quote" style="max-width:350px;margin-top:20px;justify-content:center;">
                  <p class="quote-marks" style="font-size: 2.5rem;font-weight:bold;">“ไปตลอด!”</p>
                  <p class="quote-footer">เอย, บัว, ท็อป, เอิร์ธ, วี</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div style="height: 100%;padding-top:20px;padding-bottom:60px;">
          <div class="flex-grid">
            <div class="col" style="align-self: center;">
              <h3 style="margin-bottom:20px;">พื้นเพของพวกเขาก็มี<strong>ความหลากหลาย</strong></h3>
              <div class="flex-grid">
                <div class="col" style="align-self: center;">
                  <div class="pre-quote-1">
                    <div class="quote" style="justify-content:center;">
                      <p class="quote-marks">“เราเป็นอดีตกปปส. ไปกินข้าวบ้านแฟนแล้วรู้สึกว่าทักษิณโกงหรือเปล่า?”</p>
                      <p class="quote-footer">เอย, 27</p>
                      <img src="@/assets/aey.png" class="quote-avatar">
                    </div>
                  </div>
                </div>
                <div class="col" style="align-self: center;">
                  <div class="pre-quote-2">
                    <div class="quote" style="justify-content:center;">
                      <p class="quote-marks">“สื่อหลักที่เราเสพได้มีแค่หนังสือพิมพ์กับช่องข่าวใหญ่ สมัยนั้นไม่เหมือนตอนนี้ ม.4
                ไม่มีใครคุยการเมืองกันหรอก”</p>
                      <p class="quote-footer">ท็อป, 25</p>
                      <img src="@/assets/top.png" class="quote-avatar">
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-grid">
                <div class="col" style="align-self: center;">
                  <div class="pre-quote-1">
                    <div class="quote" style="justify-content:center;">
                      <p class="quote-marks">“สมัยพันธมิตร ครูที่โรงเรียนเปิดพันธมิตรกรอกหู แต่เราขวางโลกตั้งแต่เด็ก เลยรู้สึกไม่ชอบ”</p>
                      <p class="quote-footer">วี, 23</p>
                      <img src="@/assets/vee.png" class="quote-avatar">
                    </div>
                  </div>
                </div>
                <div class="col" style="align-self: center;">
                  <div class="pre-quote-2">
                    <div class="quote" style="justify-content:center;">
                      <p class="quote-marks">“รัฐประหารตั้งแต่เข้ามหาลัย เราได้รับผลกระทบเกือบไม่ได้เรียนต่อต่างประเทศ”</p>
                      <p class="quote-footer">บัว, 25</p>
                      <img src="@/assets/bua.png" class="quote-avatar">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="flex-grid">
          <div class="col" style="align-self: center;">
            <h3>แต่ในห้วงเวลานี้</h3>
            <h3>พวกเขากลับต้องเผชิญเงื่อนไขที่ทำให้พวกเขา<br><strong>ลืมตาอ้าปากไม่ได้</strong></h3>
            <hr style="width:50%; margin-top:20px;margin-bottom:20px;">
            <h4>งานวิจัยหลายชิ้นชี้ให้เห็นว่า Gen Y <br><strong>ลำบากในการจะประสบความสำเร็จแบบรุ่นก่อน</strong><sup>2</sup></h4>
            <div style="display:flex;justify-content: center;margin-top:20px;">
                <div class="quote">
                  <p class="quote-marks">“เศรษฐกิจมันแย่มาก แล้วรัฐบาลไม่เห็นค่าเราเลย แล้วผมตกงาน เลยมาเรียกร้องเท่าที่เราจะทำได้”</p>
                  <p class="quote-footer">เอิร์ท, 31</p>
                  <img src="@/assets/earth.png" class="quote-avatar">
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="flex-grid question">
          <div class="col" style="align-self: center;">
            <h3 style="font-weight:bold">ฟังจากปากพวกเขา.. </h3>
            <h4>ทำไม Gen Y จึงออกมามากที่สุด?</h4>
          </div>
          <div class="col" style="align-self: center;">
            <video class="video" controls data-autoplay playsinline>
              <source src="@/assets/why.mp4" type="video/mp4">
              Your browser does not support HTML5 video.
            </video>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="flex-grid question">
          <div class="col" style="align-self: center; color:#fff;">
        <h3>และพวกเขามี<strong>แรงกาย</strong> <br>(อายุยังไม่มาก) <br> <strong>เงินประมาณหนึ่ง</strong> <br>(มีเงินเดือน) <br>และ<strong>เสรีภาพ</strong> <br>(เพราะโตแล้ว) <br>ในการออกมาชุมนุม
        </h3>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="flex-grid question">
          <div class="col" style="align-self: center;">
        <h3>แต่นี่เป็นม็อบ ‘นักศึกษา’ <br>ไม่ใช่ม็อบคนเพิ่งทำงาน</h3>
        <h4 style="font-weight:bold;">แล้วทำไม Gen Y ถึงควรออกมา?</h4>
        </div>
         <div class="col" style="align-self: center;">
        <video class="video" controls data-autoplay playsinline>
          <source src="@/assets/final.mp4" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
          </div>
        </div>
      </div>
      <div class="section" style="color:#fff;">
        <div class="flex-grid">
          <div class="col" style="align-self: center;">
            <h4 style="margin-bottom: 10px;">ขอขอบคุณ</h4>
            <p>อ.หยิน กนกรัตน์ เลิศชูสกุล ที่ให้ไอเดียคร่าว ๆ (แม้ผมจะทำตามเท่าที่คิดไม่ได้)</p>
            <p>คุณเอย, คุณบัว, คุณเอิร์ธ, คุณท็อป, คุณวี และท่านอื่น ๆ</p>
            <p>สัมภาษณ์ ณ วันที่ 10 ธันวาคม 2563 ที่อนุสรณ์สถาน 14 ตุลาฯ <br>กระทรวงพัฒนาสังคมและความมั่นคงของมนุษย์ <br>และบริเวณหน้าทำเนียบรัฐบาล</p>
            <hr style="width: 50px;margin-top:20px;margin-bottom:20px;">
        <h4>อ้างอิง</h4>
        <p><sup>1</sup> ขอบคุณข้อมูลจาก เนิร์ดข้างบ้าน เข้าถึงได้ที่ <a href="https://nerdnextdoors.com/2020/11/19/%e0%b9%87%e0%b8%9c%e0%b8%a5%e0%b8%aa%e0%b8%b3%e0%b8%a3%e0%b8%a7%e0%b8%88%e0%b8%ad%e0%b8%b2%e0%b8%a2%e0%b8%b8%e0%b8%84%e0%b8%99%e0%b9%83%e0%b8%99%e0%b8%a1%e0%b9%87%e0%b8%ad%e0%b8%9a%e0%b8%84%e0%b8%a3/">https://nerdnextdoors.com/2020/11/19/็ผลสำรวจอายุคนในม็อบคร</a></p>
        <p><sup>2</sup> ตัวอย่างเช่น <a href="https://www.theguardian.com/business/2017/sep/20/gen-y-has-done-what-it-takes-to-succeed-so-why-are-the-basics-of-adult-life-unattainable">https://www.theguardian.com/business/2017/sep/20/gen-y-has-done-what-it-takes-to-succeed-so-why-are-the-basics-of-adult-life-unattainable</a></p>
        <p>ภาพหน้าปกจาก <a href="https://www.bbc.com/news/world-asia-54581830">https://www.bbc.com/news/world-asia-54581830</a></p>
          <h5 style="margin-top:40px;">ศุภณัฐ อเนกนำวงศ์ (6240049624)</h5>
          </div>
          </div>
      </div>
    </full-page>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  export default {
    name: 'App',
    data() {
      return {
        options: {
          licenseKey: '31456A95-8AEF4E03-923D2410-151B778C',
          afterLoad: this.afterLoad,
          anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6', 'page7', 'page8', 'page9', 'page10',
            'page11'],
          sectionsColor: ['#fff', '#fff', '#11110e', '#fff', '#fff', '#fff', '#fff', '#fff', '#11110e', '#fff', '#11110e'],
          scrollOverflow:true
        },
      }
    },
  }
</script>

<style lang="scss">
.video {
  width:80%;
}
  .img-diagram {
    width: 90%;
  }

  * {
    box-sizing: border-box;
  }

  .flex-grid {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }

  .flex-grid .col {
    flex: 50%;
    padding: 10px;
  }

  sup {
    font-size: 1rem;
  }

  .question h4 {
    font-style: italic;
  }

  #app,
  body,
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  fieldset,
  input,
  textarea,
  p,
  blockquote,
  th,
  td {
    padding: 0;
    margin: 0;
  }

  #section1 {
    background-image: url('~@/assets/protest2.jpg');
    background-size: cover;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    font-size: 100%;
    margin: 0;
    padding: 0;
    letter-spacing: normal;
  }

  h2 {
    font-size: 4.5rem;
  }

  h3 {
    font-size: 3rem;
    text-align: center;
  }

  h4 {
    font-size: 2.5rem;
  }

  body {
    font-family: Anuphan;
    color: #11110e;
  }
.myname {
  position:absolute;font-weight:bold;color:#fff;z-index:9999;padding:20px;font-size:1.25rem;text-align:left;
}
  .section {
    position: relative;
    text-align: center;
  }

  #section-1 li {
    display: inline-block;
    margin: 1.25em 0.3em;
  }

  .section-1-button {
    padding: 0.93em 1.87em;
    background: #35495e;
    border-radius: 5px;
    display: block;
    color: #fff;
  }

  .quote {
    width: 100%;
    max-width: 500px;
    height: auto;
    background: rgba(black, 1);
    padding: 10px 10px;
    color: white;
    text-align: center;
    position: relative;
    margin-bottom: 60px;
  }

  .quote:after {
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    bottom: -20px;
    right: 100px;
    border-style: solid;
    border-width: 0 50px 20px 0;
    border-color: transparent rgba(black, 1) transparent transparent;
    z-index: 2;
  }

  .quote-footer {
    font-size: 16px;
    margin-top: 10px;
  }

  .quote-avatar {
    position: absolute;
    height: 60px;
    width: 60px;
    bottom: -65px;
    right: 20px;
    border-radius: 50%;
    border: 5px solid black;
    background: black;
  }

  .pre-quote {
    display: flex;
    justify-content: center;
  }

  .pre-quote-1 {
    display: flex;
    justify-content: flex-end;
  }

  .pre-quote-2 {
    display: flex;
    justify-content: flex-start;
  }
 a {
   text-decoration: underline;
   color:#fff;
 }
  @media (max-width: 400px) {
    .flex-grid {
      display: flex;
      height: 100%;

      .col {
        flex: 100%;
      }
    }

    h2 {
      font-size: 3.4rem;
    }

    h3 {
      font-size: 2.1rem;
      text-align: center;
    }

    h4 {
      font-size: 1.4rem;
    }

    .pre-quote-1 {
      display: flex;
      justify-content: center;
    }

    .pre-quote-2 {
      display: flex;
      justify-content: center;
    }

    .quote {
      width: 100%;
      max-width: 500px;
      height: auto;
      background: rgba(black, 1);
      padding: 10px 10px;
      color: white;
      text-align: center;
      position: relative;
      margin-bottom: 30px;
    }

    .quote:after {
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      bottom: -10px;
      right: 60px;
      border-style: solid;
      border-width: 0 40px 15px 0;
      border-color: transparent rgba(black, 1) transparent transparent;
      z-index: 2;
    }

    .quote-avatar {
      position: absolute;
      height: 40px;
      width: 40px;
      bottom: -45px;
      right: 20px;
      border-radius: 50%;
      border: 5px solid black;
      background: black;
    }

    p {
      font-size: 0.8rem;
    }

    .quote-footer {
      font-size: 0.8rem;
    }
    .video {
  width:100%;
}
.myname {
  font-size: 0.8rem;
}
  }
</style>